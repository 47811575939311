.header-unauth {
  text-align: right;
  padding: 0 10px; }

.header-container {
  position: relative;
  display: flex;
  width: 100vw;
  overflow: hidden;
  flex-wrap: nowrap;
  height: 50px; }

.dark.header-container .ant-menu-vertical {
  text-align: center; }

.dark.header-container > .anticon {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 20px;
  z-index: 10;
  padding: 10px; }

.dark.header-container > .header-main-link {
  display: none; }

.main-link {
  font-family: 'Roboto Slab', serif;
  font-size: 18px;
  text-transform: uppercase; }

.dark .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent; }

.dark .ant-menu-horizontal > .ant-menu-item:hover,
.dark .ant-menu-horizontal > .ant-menu-submenu:hover,
.dark .ant-menu-horizontal > .ant-menu-item-active,
.dark .ant-menu-horizontal > .ant-menu-submenu-active,
.dark .ant-menu-horizontal > .ant-menu-item-open,
.dark .ant-menu-horizontal > .ant-menu-submenu-open,
.dark .ant-menu-horizontal > .ant-menu-item-selected,
.dark .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-top: 2px solid #e0c592;
  border-bottom: 2px solid transparent;
  color: #fff; }

.dark .ant-menu-horizontal {
  border-bottom: none;
  background-color: #5b514c; }

.is-main .ant-menu-horizontal {
  background-color: transparent; }

.dark .ant-menu-horizontal > .ant-menu-item > button,
.dark .ant-menu-horizontal > .ant-menu-item > a {
  color: #fff;
  opacity: 0.6; }

.dark .ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected > a,
.dark .ant-menu-horizontal > .ant-menu-item > button:hover,
.dark .ant-menu-horizontal > .ant-menu-item > button:focus,
.dark .ant-menu-horizontal > .ant-menu-item > a:hover,
.dark .ant-menu-horizontal > .ant-menu-item > a:focus {
  outline: none;
  color: #fff;
  opacity: 1; }

.dark .ant-menu-horizontal .ant-select-selection {
  background: transparent;
  color: #fff;
  border: none;
  opacity: 0.6; }

.dark .ant-menu-horizontal .ant-select-open .ant-select-selection,
.dark .ant-menu-horizontal .ant-select-focused .ant-select-selection,
.dark .ant-menu-horizontal .ant-select-selection:focus,
.dark .ant-menu-horizontal .ant-select-selection:active {
  box-shadow: none; }

.dark .ant-menu-horizontal .ant-select-arrow {
  color: #fff;
  opacity: 0.6; }

.dark .ant-menu-horizontal .ant-select-selection-selected-value {
  text-transform: uppercase; }

.right-submenu {
  flex: 0 0 320px;
  padding-right: 30px; }

.center-submenu {
  flex: 1 5 300px;
  padding-left: 30px;
  overflow: hidden; }

@media (max-width: 820px) {
  .header-container {
    display: block;
    min-height: 48px; }
  .header-container.opened {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 8;
    height: 100vh; }
  .header-container > .anticon {
    display: block; }
  .header-container.opened > .anticon {
    position: fixed; }
  .header-container > .header-main-link {
    display: block; }
  .header-container > .ant-menu-horizontal {
    display: none; }
  .header-container.opened > .header-main-link {
    display: none; }
  .header-container .ant-menu-vertical {
    text-align: center;
    overflow: auto; }
    .header-container .ant-menu-vertical.right-submenu {
      padding-bottom: 20px; }
  .right-submenu {
    padding: 0; }
  .center-submenu {
    padding: 0; } }

.left-top-menu-item {
  padding: 0 15px; }

.ant-menu-submenu-title {
  position: relative;
  top: 2px;
  color: #fffeee;
  font-size: 22px; }

.footer {
  background: #5b514c;
  height: 60px;
  /*border-top: 1px solid #d9d9d9;*/
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000; }
  .footer a {
    margin: 0 10px;
    color: #fff;
    opacity: 0.6; }
    .footer a:focus, .footer a:hover {
      opacity: 1; }
  .footer img {
    height: 36px;
    margin: 12px 22px;
    float: right;
    border-radius: 5px;
    opacity: 0.7; }
  .footer .copyright {
    padding: 20px;
    display: inline-block; }
  .footer .logos {
    display: inline-block;
    float: right; }
  .footer .logos a {
    padding: 20px;
    display: inline-block; }
  .footer .cert-link__wrapper {
    display: inline-block;
    float: right; }
    .footer .cert-link__wrapper .cert-link {
      margin: 0;
      padding: 0;
      display: inline-block;
      opacity: 1; }

@media (max-width: 820px) {
  .footer {
    text-align: center;
    position: relative;
    height: inherit;
    z-index: inherit; }
    .footer .copyright {
      display: block;
      padding: 10px;
      margin-top: 10px; }
    .footer .logos {
      float: none;
      margin-bottom: 10px; }
    .footer .logos a {
      display: block;
      padding: 5px; }
    .footer img {
      float: none; } }

.home-result-container {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.home-result-container .left-panel {
  width: 48%;
  height: 800px;
}

.home-result-container .right-panel {
  width: 48%;
  height: 800px;
}

.left-panel .left-panel-block {
  width: 80%;
  height: 300px;
  margin: 20px auto;
  border: 1px solid lightgray;
}

.left-panel .left-panel-search {
  width: 80%;
  height: 50px;
  margin: 20px auto;
}

.left-panel-where .leaflet-tile-container {
  filter: grayscale(100%);
}

.right-panel  .ant-list-item:hover {
  background: #faf9f9;
}

.right-panel .list-item-avatar {
  margin-top: 10px;
}

a.list-item-link {
  color: #d4af84;
  font-weight: 600;
}

span.list-item-type {
  color: gray;
  font-weight: lighter;
  cursor: default;
  font-size: 12px;
}

span.list-item-type-value {
  margin-left: 10px;
  border-radius: 10px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 2px;
  font-size: 12px;
  color: white;
  cursor: default;
  font-weight: lighter;
  background-color: #d4af84;
}

.right-panel-top-text {
  font-size: 12px;
  color: gray;
  font-weight: lighter;
}

.right-panel-top-elem {
  margin-left: 7px;
  font-size: 12px;
  color: black;
  font-weight: normal;
}

.right-panel .ant-list-item {
  width: 100%;
}

.right-panel .ant-pagination-item-active {
  border-color: #d4af84;
}
.right-panel .ant-pagination-item-active::selection {
  border-color: #d4af84;
}
.right-panel .ant-pagination-item-active:hover {
  border-color: #d4af84;
}
.right-panel .ant-pagination-item-active a {
  color: #d4af84;
}
.home-result-pagination-top {
  width: 50%;
  float: right;
  text-align: right;
}
.home-result-pagination-bot {
  text-align: right;
}

.bolgar-index-bg {
  background-color: rgba(0,0,0,0) !important;
  height: 100% !important;
  width: 100% !important;
}

.bolgar-index-bg-img {
  opacity: 1 !important;
  width: 107% !important;
  height: 100% !important;
}

.bolgar-index-main {
  margin-top: 150px !important;
}

.bolgar-button {
  background: rgba(0,0,0,0) !important;
  border-radius: 0px !important;
  border: solid 2px white !important;
  width: 200px !important;
  height: 40px !important;
  padding-top: 3px !important;
  color: white !important;
  text-align: center !important;
}

.bolgar-button:hover {
  color: black !important;
  border: solid 2px white !important;
  background: rgba(255,255,255,0.8) !important;
}

.bolgar-index-left-button {
  margin-left: 32%;
}

.bolgar-index-right-button {
  margin-left: 15px;
}

.bolgar-button img.svg{
  fill: black;
  color: black;
  width: 30px;
  position: relative;
  z-index: 1;
  background-color: transparent;
  margin-left: 5px;
  margin-top: -9px;
  left: 0;
  transition: all 0.3s;
}

.bolgar-button img.svg_bolrag{
  fill: black;
  color: black;
  width: 30px;
  position: relative;
  z-index: 1;
  background-color: transparent;
  margin-left: 5px;
  margin-top: -9px;
  left: 0;
  transform: scale(-1, 1);
  transition: all 0.3s;
}

.index-bg {
  top: 0;
  position: absolute;
  z-index: -1;
  background-color: #5b514c;
  width: 100vw;
  overflow: hidden;
  height: 456px;
  perspective: 4000px; }
  .index-bg .cd-floating-background {
    transform-style: preserve-3d;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.2s; }
  .index-bg .people {
    position: absolute;
    /*transform: translateZ(50px);*/
    backface-visibility: hidden;
    transition: all 0.5s ease-out; }
  .index-bg .people.first {
    top: 30px;
    left: 0; }
  .index-bg .people.second {
    top: 230px;
    left: 550px;
    /*transform: translateZ(450px);*/ }
  .index-bg .people.third {
    top: 0;
    left: 940px;
    /*transform: translateZ(150px);*/ }
  .index-bg .people.fourth {
    top: 150px;
    left: 1538px; }
  .index-bg .index-bg-img {
    opacity: 0.18;
    width: 110%;
    height: 110%;
    object-fit: cover;
    left: -5%;
    position: relative;
    top: -5%; }

.index main {
  color: #fff;
  max-width: 1200px;
  margin: 33px auto;
  padding: 0 15px; }
  .index main header {
    text-align: center; }

.index h1 {
  color: #fff;
  font-family: 'Roboto Slab', 'Roboto', serif;
  font-size: 48px;
  text-transform: uppercase; }

.index .columns {
  display: flex;
  justify-content: space-between; }

.index .short-column {
  width: 380px;
  display: inline-block; }
  .index .short-column .tile {
    height: 245px; }

.index .wide-column {
  max-width: 775px;
  margin-right: 15px;
  flex: 1 2 775px; }
  .index .wide-column .tile {
    height: 505px; }

.index .tile {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  box-shadow: 0px 5px 20px rgba(91, 81, 76, 0.3);
  transition: all 0.3s; }
  .index .tile:hover {
    box-shadow: 0px 5px 20px 4px rgba(91, 81, 76, 0.3); }
  .index .tile:hover img.svg {
    left: 10px; }
  .index .tile img {
    top: inherit;
    bottom: 0; }
    .index .tile img.svg {
      width: 30px;
      position: relative;
      z-index: 1;
      background-color: transparent;
      margin-left: 5px;
      margin-top: -9px;
      left: 0;
      transition: all 0.3s; }
  .index .tile > a:hover {
    opacity: 0.5; }
  .index .tile img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: white; }

.index .what {
  cursor: default;
  background-color: white; }

.index h3 {
  font-family: 'Roboto Slab', 'Roboto', sans-serif;
  text-transform: uppercase;
  float: left;
  font-size: 18px;
  color: white;
  background-color: #e0c592;
  display: inline-block;
  padding: 10px 30px; }

.index .search-block {
  margin: 46px 0; }
  .index .search-block-select.ant-select {
    width: 25%; }
  .index .search-block-input {
    width: 75%;
    top: 0; }
    .index .search-block-input input:hover {
      border-color: white; }
  .index .search-block .ant-select-selection--single {
    height: 46px;
    background-color: transparent;
    color: #fff; }
  .index .search-block .ant-select-open .ant-select-selection {
    box-shadow: none;
    border-color: #fff; }
  .index .search-block .ant-select-selection:focus, .index .search-block .ant-select-selection:active {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    border-color: #fff; }
  .index .search-block .ant-select-arrow {
    color: #fff;
    opacity: 0.6; }
  .index .search-block .ant-select-selection__rendered {
    line-height: 44px;
    margin-left: 30px; }
  .index .search-block .ant-input-search input {
    color: #fff;
    height: 46px;
    background-color: transparent;
    border-radius: 0; }
  .index .search-block .ant-input-search-enter-button .ant-btn-primary {
    background-color: #e0c592;
    border-color: #e0c592;
    width: 48px;
    height: 46px;
    border-radius: 0;
    transition: opacity 0.3s; }

.index .search-block .ant-input-search-enter-button .ant-btn-primary:hover,
.index .search-block .ant-input-search-enter-button .ant-btn-primary:focus {
  opacity: 0.8; }

.index .search-block .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection {
  border-radius: 0;
  border-right-width: 0 !important; }

.index .search-block .ant-select-selection:hover,
.index .search-block .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #fff;
  border-right-width: 0 !important; }

.index .search-block .ant-input:focus {
  border-color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2); }

@media (max-width: 820px) {
  .dark.header-container > .header-main-link {
    display: block; }
  .dark.header-container .anticon {
    color: #b1acaa;
    display: block; }
  .dark.header-container {
    background-color: #5b514c; }
  .container {
    width: 100vw;
    overflow: hidden;
    margin-bottom: 0 !important; }
  .index-bg {
    display: none; }
  .index main {
    width: 100%;
    color: #2d2d2d; }
  .index h1 {
    color: #2d2d2d;
    font-size: 18px; }
  .index .search-block {
    margin: 20px 0; }
    .index .search-block-input {
      width: 100%; }
  .index .search-block .ant-input-search input {
    color: #2d2d2d; }
  .index .columns {
    display: block; }
  .index .wide-column .tile {
    height: 245px; }
  .index .search-block .ant-select-selection:hover,
  .index .search-block .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
  .index .search-block .ant-input:focus,
  .index .search-block .ant-input:hover {
    border-color: #c0c0c0; }
  .index .wide-column,
  .index .short-column {
    width: 100%; }
  .search-block .ant-select.ant-select-enabled {
    display: none; }
  span.ant-input-search.ant-input-search-enter-button.ant-input-affix-wrapper {
    /* TODO rewrite inline styles on index page in search select */
    width: 100% !important; }
  .container__content {
    overflow: auto; } }

.bolgar-categories-main {
  color: #fff;
  width: 1300px;
  margin: 33px auto;
  padding: 0 15px;
  -webkit-user-select: none;
          user-select: none;
}

.bolgar-categories-main header {
  text-align: center;
}

.bolgar-categories-main h1 {
  color: black;
  font-family: 'Roboto Slab', serif;
  font-size: 48px;
  text-transform: uppercase;
}

.bolgar-categories-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bolgar-category {
  width: 266px;
  height: 147px;
  margin-left: 20px;
  margin-top: 45px;
  background: rgba(240, 238, 237, 0.7);
  background-image: url(/static/media/border1.a681e83d.webp);
  background-size: cover;
  color: black;
  opacity: 0.8;
  cursor: pointer;
}

.bolgar-category:hover {
  background: rgba(240, 238, 237, 1.3);
  background-image: url(/static/media/border2.9f205181.webp);
  background-size: cover;
}

.bolgar-category-img {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  height: 70px;
  object-fit: scale-down;
}

.bolgar-category-text {
  font-family: 'Roboto Slab', serif;
  text-transform: uppercase;
}

.bolgar-index-bg {
  background-color: rgba(0,0,0,0) !important;
  height: 100% !important;
  width: 100% !important;
}

.bolgar-index-bg-img {
  opacity: 1 !important;
  width: 107% !important;
  height: 100% !important;
}

.bolgar-index-main {
  margin-top: 150px !important;
}

.bolgar-button {
  background: rgba(0,0,0,0) !important;
  border-radius: 0px !important;
  border: solid 2px white !important;
  width: 200px !important;
  height: 40px !important;
  padding-top: 3px !important;
  color: white !important;
  text-align: center !important;
}

.bolgar-button:hover {
  color: black !important;
  border: solid 2px white !important;
  background: rgba(255,255,255,0.8) !important;
}

.bolgar-index-left-button {
  margin-left: 32%;
}

.bolgar-index-right-button {
  margin-left: 15px;
}

.bolgar-button img.svg{
  fill: black;
  color: black;
  width: 30px;
  position: relative;
  z-index: 1;
  background-color: transparent;
  margin-left: 5px;
  margin-top: -9px;
  left: 0;
  transition: all 0.3s;
}

.bolgar-button img.svg_bolrag{
  fill: black;
  color: black;
  width: 30px;
  position: relative;
  z-index: 1;
  background-color: transparent;
  margin-left: 5px;
  margin-top: -9px;
  left: 0;
  transform: scale(-1, 1);
  transition: all 0.3s;
}

.bolgar-categories-main {
  color: #fff;
  width: 1300px;
  margin: 33px auto;
  padding: 0 15px;
  -webkit-user-select: none;
          user-select: none;
}

.bolgar-categories-main header {
  text-align: center;
}

.bolgar-categories-main h1 {
  color: black;
  font-family: 'Roboto Slab', serif;
  font-size: 48px;
  text-transform: uppercase;
}

.bolgar-categories-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bolgar-category {
  width: 266px;
  height: 147px;
  margin-left: 20px;
  margin-top: 45px;
  background: rgba(240, 238, 237, 0.7);
  background-image: url(/static/media/border1.a681e83d.webp);
  background-size: cover;
  color: black;
  opacity: 0.8;
  cursor: pointer;
}

.bolgar-category:hover {
  background: rgba(240, 238, 237, 1.3);
  background-image: url(/static/media/border2.9f205181.webp);
  background-size: cover;
}

.bolgar-category-img {
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  height: 70px;
  object-fit: scale-down;
}

.bolgar-category-text {
  font-family: 'Roboto Slab', serif;
  text-transform: uppercase;
}

.app-outer {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 30);
  padding-top: 30px;
}

.app-inner {
  height: 100%;
  width: 100%;
}

.tag-cloud {
  height: 100%;
  width: 100%;
}

.tag-cloud > div {
  cursor: pointer;
}

.tag-cloud > div:hover {
  opacity: 0.6;
}

.tag-item-wrapper {
  cursor: pointer;
}

.tag-item-wrapper:hover {
  z-index: 100;
}

.what-page {
  height: 600px;
}

.when-container {
  position: relative;
  width: 1150px;
  margin: auto;
}

.when-container-slider {
  width: 1100px;
  float: right;
}

button.when-button {
  z-index: 1000;
  bottom: 10px;
  border-radius: 0;
  background-color: #e0c592;
  border-color: #e0c592;
  font-size: 18px;
  height: 40px;
  margin-top: 20px
}

button.when-button:hover,
button.when-button:focus {
  background-color: #eed39e;
  border-color: #eed39e;
  box-shadow: 0 0 0 2px rgba(224, 197, 146, 0.4);
}

.when-button-container {
  text-align: center;
}

.where-container {
  position: relative;
  height: calc(100vh - 109px);
}

.where-container .leaflet-tile-container {
  filter: grayscale(100%);
}

.where-container .leaflet-control-layers {
  display: none;
}

.where-container .leaflet-marker-icon.leaflet-div-icon {
  background: #fff;
  border: 1px solid #7d7d7d;
}

button.where-button {
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  border-radius: 0;
  background-color: #e0c592;
  border-color: #e0c592;
  font-size: 18px;
  height: 45px;
}

button.where-button:hover,
button.where-button:focus {
  background-color: #eed39e;
  border-color: #eed39e;
  box-shadow: 0 0 0 2px rgba(224, 197, 146, 0.4);
}

.where-button-container {
  display: flex;
  justify-content: center;
}

.font-family-mixin {
  font-family: 'Roboto', sans-serif; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden; }

button.link {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer; }

.background {
  display: block;
  width: 100vw;
  min-height: calc(135vh);
  z-index: -1;
  position: absolute;
  top: -35px;
  left: 0;
  background-size: cover;
  height: calc(135%); }

.background + .search-panel {
  margin: auto; }

.container {
  margin-bottom: 60px; }

form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group, form:not(.ant-form-vertical) .ant-input-group-wrapper {
  top: 0 !important; }

.gallery-slide {
  min-height: 450px !important;
  height: content-box !important; }

.ant-table-wrapper {
  min-height: 700px; }

.ant-table-placeholder {
  z-index: 0; }

@font-face {
  font-family: 'Izhitsa Regular';
  src: url("/Fonts/Izhitsa-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'BukyVede';
  src: url("/Fonts/BukyVede-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Fedorovsk Unicode';
  src: url("/Fonts/FedorovskUnicode.ttf");
  font-weight: normal;
  font-style: normal; }


.login-form.ant-form {
  max-width: 300px;
  padding: 10px;
  margin: 0 auto;
}

.login-form-button {
  width: 100%;
}

.footer_link {
  text-align: left;
  margin-top: 10px;
}

.footer_link a {
  display: block;
}

.container.login {
  text-align: center;
  margin-top: 20px;
}

.sign_up_form.ant-form {
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
}

.footer_link {
  text-align: left;
  margin-top: 10px;
}

.ant-list-item.organization-list-item {
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
  position: relative;
}

.ant-list-item.organization-list-item i {
  position: absolute;
  top: 20px;
  right: -18px;
  cursor: pointer;
}

.organization-add-button {
  margin-bottom: 25px;
}

.capthca {
  width: 100%;
  text-align: right;
}

.capthca > * > * > * {
  display: inline-block;
}

.instr_link {
  text-align: center;
  margin-bottom: 25px;
}
.select-turn-off-selected li.ant-select-selection__choice {
    display: none;
}
.user-card {
    text-align: left;
    margin-bottom: 10px;
}

.user-card .ant-card-head-title {
    white-space: inherit
}

.user-card .ant-card-extra button{
    background: transparent;
    border: none;
    cursor: pointer;
    color: #188fff;
}
.user-card .ant-card-extra button:hover,
.user-card .ant-card-extra button:focus {
    outline: none;
    opacity: 0.8;
}

.user-card.ant-card-type-inner .ant-card-head-title {
    padding: 16px 0;
}

.ant-card-type-inner .ant-card-body > * {
    margin-bottom: 10px;
}
.container.sign_up {
  text-align: center;
  margin-top: 20px;
}

.ant-collapse > .ant-collapse-item.custom_panel .ant-collapse-content > .ant-collapse-content-box {
  padding: 0; }

.custom_collapse {
  border-radius: 0;
  border: none;
  background: #fbfbfb; }

.loading-subject {
  position: absolute;
  top: 9px;
  right: 30px;
  font-size: 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: border 0.3s; }
  .loading-subject > i {
    padding: 10px; }
  .loading-subject:hover {
    border: 2px solid #e8e8e8; }
  .loading-subject-content {
    max-width: 500px; }
    .loading-subject-content .ant-divider-horizontal.ant-divider-with-text, .loading-subject-content .ant-divider-horizontal.ant-divider-with-text-left, .loading-subject-content .ant-divider-horizontal.ant-divider-with-text-right {
      margin: 5px 0; }
  .loading-subject-title {
    text-align: center;
    font-size: 18px;
    color: #575757c2; }
  .loading-subject-list .ant-collapse > .ant-collapse-item.custom_panel .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px; }
  .loading-subject-list .ant-tag {
    margin-bottom: 5px;
    padding: 5px 10px;
    width: 100%;
    white-space: initial; }
  .loading-subject-list-item {
    display: flex;
    align-items: center; }
    .loading-subject-list-item span {
      flex: 1 1; }

.code_snippet {
  position: relative; }
  .code_snippet pre {
    background: #ece8e8;
    padding: 10px;
    border-radius: 4px;
    padding-right: 30px; }
  .code_snippet_button {
    position: absolute;
    top: 9px;
    right: 5px;
    background: white;
    padding: 4px;
    border-radius: 4px;
    opacity: 0.8;
    transition: opacity 0.3s; }
    .code_snippet_button:hover {
      opacity: 1; }

.point_history_item {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #cecece; }
  .point_history_item_info {
    display: flex; }
  .point_history_item .accuracy {
    flex: 1 0;
    text-align: center; }
  .point_history_item .linked_by {
    flex: 1 0; }
  .point_history_item .date {
    flex: 1 0;
    text-align: center;
    color: #a5a3a3; }
  .point_history_item .delete p {
    flex: 1 0;
    text-align: right;
    cursor: pointer;
    color: red; }

.setting-panel {
  position: absolute;
  top: 3px;
  right: 5px;
  top: 16px;
  font-size: 20px; }

.data-left-panel {
  top: 100px;
  z-index: 1000;
  transform-origin: 100% 0;
  margin-bottom: 10px;
  margin-left: -20px;
  margin-top: -30px; }
  .data-left-panel Button {
    background: #e7e7e7; }

.data-left-panel-drawer .ant-drawer-body {
  padding: 30px 5px 5px 5px; }

.data-left-panel-drawer Button.edit-button {
  padding: 5px 0px 0px 0px;
  margin-top: 20px;
  width: 40px;
  height: 40px; }

.data-left-panel-drawer .edit-switcher {
  width: 40px;
  margin-left: -2px; }

.add-spatials-modal-window {
  display: flex; }

.add-spatials-modal-left-panel {
  width: 100%; }

.add-spatials-modal-map-panel {
  width: 100%;
  height: 700px; }

.ant-select {
  width: 100%; }

.create-panel {
  text-align: left;
  width: 51%;
  height: 100%;
  padding: 10px 0 75px 0; }

.create-panel .ant-input-number-handler-wrap {
  width: 0px; }

.create-panel .ant-form-item {
  margin-bottom: 10px; }

.create-panel-modal-half {
  margin-bottom: 10px;
  width: 45vw !important;
  margin-left: 4rem;
  margin-right: auto; }

.create-panel-modal .ant-form-item {
  margin-bottom: 10px; }

.create-panel .ant-input-number {
  width: 40%;
  margin-right: 2%; }

.create-panel-add-tab-modal {
  margin-left: 50%; }

.create-panel-add-tab-modal .ant-modal-body {
  padding: 0px; }

.create-panel-add-tab-modal .ant-list-item-meta-title {
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif; }

.create-panel-add-tab-modal .ant-list-item {
  font-size: 16px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid lightgray; }

.create-panel-add-tab-modal .ant-list-item:hover {
  cursor: pointer;
  background: lightgray; }

.create-panel-header {
  max-width: 300px;
  padding-left: 15px;
  margin: 0 auto; }

.create-form .ant-form {
  padding-right: 100px; }

.create-form-button {
  margin-top: 30px;
  width: 100%;
  white-space: normal;
  height: auto;
  min-height: 32px; }

.create-panel-modal-header {
  width: 100%;
  text-align: center;
  margin-bottom: 30px; }

.auto-input-option-new {
  color: #888;
  font-weight: 400; }

.create-panel-relations-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center; }

.create-panel-lower-title {
  font-size: 16px; }

.ant-select-dropdown-menu-item {
  white-space: normal; }

.create-panel-modal .ant-progress-inner {
  background: white; }

.create-panel-modal .anticon-spin {
  display: none; }

.add-interactive-marker-button {
  background: white; }

.add-interactive-marker-button i {
  color: rgba(0, 0, 0, 0.5); }

.drag_drop_warning {
  font-size: 13px;
  font-style: italic; }

.vsplit {
  display: flex;
}

.vsplit-item {
  padding-right: 10px;
}


.ant-list-item-meta-title {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0px;
}

.sort-container {
  margin-bottom: 10px; }

div.show-group-by {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  div.show-group-by label.show-group-by {
    margin: 0 10px; }

.gallery-slide {
  overflow: hidden;
}

.gallery-slide img{
  max-height: 300px;
  margin: 0 auto;
}

.gallery-field {
  margin-bottom: 30px;
}
.common-field-text {
  color: rgba(0, 0, 0, 0.8);
  white-space: pre-wrap;
}

.edit-icon {
  margin-left: 30px;
  cursor: pointer;
}

.ant-select.common-field-text-edit {
  width: 50%;
  float: left;
}

.ant-input.common-field-text-edit {
  width: 50%;
}

.slick-slider {
  margin: 30px;
}

.slick-prev:before, .slick-next:before {
  color: black;
}

.slick-slider {
  margin: 30px;
}

.slick-prev:before, .slick-next:before {
  color: black;
}

.error-text {
  color: red;
  text-align: center;
}

.radiocarbon-summary img {
  width: 100%;
}

.common-field-icon {
  margin-left: 10px;
}

.ant-tabs-content {
  padding: 0px 15px;
}

.ant-list-item-meta-description a {
  display: block;
}

.ant-tabs-tab span.ant-badge {
  margin-left: 5px;
}

.ant-spin-container .ant-list-empty-text {
  padding: 0;
}

.description-icon {
  margin-left: 5px;
    color: #a2a2a2;
}

.ant-list-item-meta-description a {
  display: inline;
}

.ant-list-item-meta-description a.block-link {
  display: block;
  float: left;
}

.data-panel {
  text-align: left;
  float: left;
  width: 50%;
  height: 100%;
  padding: 10px 15px;
  position: relative;
  padding-bottom: 80px; }

.data-panel-header {
  width: 100%;
  padding-left: 15px;
  padding-right: 50px;
  padding-bottom: 20px; }
  .data-panel-header .under-header {
    font-size: 14px;
    color: gray; }

.common-field-text a {
  margin-right: 10px; }

.data-panel.source {
  width: 100%; }

.data-panel.source .show_source {
  display: flex; }

.data-panel.source .show_source .ant-list {
  width: 50%; }

.data-panel.source .show_source .pdf {
  width: 50%;
  /* float: right; */
  border: 1px solid #d9d9d9;
  padding: 10px;
  text-align: center;
  margin: 0 10px; }

.react-pdf__Page canvas {
  margin: auto; }

.ant-collapse-header img {
  height: 16px;
  margin: 0 5px;
  position: relative;
  top: -1px; }

i.layer_label {
  font-size: 12px; }

.ant-list-item-meta-description .inline-links a {
  display: inline; }

.common-field-text .ant-timeline {
  margin-top: 20px; }

.edit-mod-switcher {
  position: absolute;
  top: 45px;
  right: 30px;
  font-size: 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: border 0.3s; }

.ant-collapse-item .ant-tag {
  margin-left: 5px; }

.leaflet-control {
  cursor: pointer;
}

a.polyline-measure-controlOnBgColor, a.polyline-measure-controlOnBgColor:hover {
  background-color: #8f8;
}

.polyline-measure-unicode-icon {
  font-size: 19px;
  font-weight: bold;
}

a.polyline-measure-clearControl:active {
  background-color: #f88;
}

.polyline-measure-tooltip {
  font: 10px Arial, Helvetica, sans-serif;
  line-height: 10px;
  background-color: rgba(255, 255, 170, 0.7);
  border-radius: 3px;
  box-shadow: 1px 1px 4px #888;
  margin: 0;
  padding: 2px;
  width: auto !important;
  height: auto !important;
  white-space: nowrap;
  text-align: right;
}

.polyline-measure-tooltip-end {
  background-color: rgba(255, 255, 40, 0.7);
}

.polyline-measure-tooltip-total {
  color: #006;
  font-weight: bold;
}

.polyline-measure-tooltip-difference {
  color: #060;
  font-style: italic;
}

.polyline-measure-popupTooltip {
  font: 11px Arial, Helvetica, sans-serif;
  line-height: 11px;
}
.leaflet-control-better-scale {
	height: 15px;
}
.leaflet-control-better-scale-upper-first-piece {
    top: 0%;
    left: 0%;
    width: 25%;
}
.leaflet-control-better-scale-upper-second-piece {
    top: 0%;
    left: 50%;
    width: 25%;
}
.leaflet-control-better-scale-lower-first-piece {
    top: 50%;
    left: 25%;
    width: 25%;
}
.leaflet-control-better-scale-lower-second-piece {
    top: 50%;
    left: 75%;
    width: 30%;
}
.leaflet-control-better-scale-ruler-block {
    overflow: hidden;
    position: absolute;
    height: 50%;
    background-color: #444444;
}
.leaflet-control-better-scale-ruler {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 7px;
    background-color: White;
    border: 1px solid #444444;
}
.leaflet-control-better-scale-label-div {
    position: relative;
    width: 100%;   
}
.leaflet-control-better-scale-label {
    position: absolute;
    width: 10%;
    text-align: center;
    color: #fff;
    font: sans-serif;
    font-weight: bold;
    font-size: 12px;
    height: 5px;
    top: -1px;
     text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}
.leaflet-control-better-scale-first-number {
    left: 45%;
}
.leaflet-control-better-scale-second-number {
    left: 95%;
}
.leaflet-container .leaflet-control-mouseposition {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  margin: 10px;
  color: #333;
  font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  border: 2px solid #c2bfba;
  border-radius: 5px;
}

#legend {
  position: absolute;
  top: 3px;
  left: 780px;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  z-index: 100;
  font-size: 1em;
  font-family: sans-serif;
  width: 165px;
  background: rgba(255,255,255,0.6);
}

.leaflet-marker-icon .label-text {
  opacity: 0;
  transition: all 0.3s;
}

.leaflet-marker-icon {
  overflow: hidden;
}

.leaflet-marker-icon:hover {
  overflow: inherit;
  z-index: 1000 !important;
}

.leaflet-marker-icon:hover .label-text {
  opacity: 1;
  alignment-baseline: middle;
  font-size: 12px;
  font-family: arial,helvetica,"sans-serif";
  fill: #000;
  font-weight: bold;
  text-shadow: 1px 1px 5px #fff;
}

.leaflet-marker-icon .label-line {
  opacity: 0;
  stroke-width: 1;
  stroke: #393939;
  transition: all 0.3s;
}

.leaflet-marker-icon:hover .label-line {
  opacity: 1;
}

.legendheading {
  position: relative;
  height: 25px;
  padding: 5px 2px 0px 2px;
  font-size: larger;
  font-weight: bold;
}
.legenditem {
  padding: 2px;
  margin-bottom: 2px;
}

svg.cluster-pie {
  overflow: initial;
}

/*Marker clusters*/
.marker-cluster-pie g.arc{
  fill-opacity: 0.5;
}
.marker-cluster-pie-label {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*Markers*/
.marker {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-radius:10px;
  margin-top: -10px;
  margin-left: -10px;
  border-style: solid;
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.marker div{
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*marker categories*/
.category-epoch-0{
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.category-epoch-1{
  fill: #ea00b6;
  stroke: #8B2286;
  background: #ea00b6;
  border-color: #8B2286;
}
.category-epoch-2{
  fill: #00D8FE;
  stroke: #00849f;
  background: #00D8FE;
  border-color: #00849f;
}
.category-epoch-3{
  fill: #FF3D19;
  stroke: #b22d13;
  background: #FF3D19;
  border-color: #b22d13;
}
.category-epoch-4{
  fill: #7E2ABB;
  stroke: #4b1c71;
  background: #7E2ABB;
  border-color: #4b1c71;
}
.category-epoch-5{
  fill: #0600F5;
  stroke: #0600b6;
  background: #0600F5;
  border-color: #0600b6;
}
.category-epoch-6{
  fill: #FF800C;
  stroke: #bc5e0a;
  background: #FF800C;
  border-color: #bc5e0a;
}
.category-epoch-7{
  fill: #8BE61B;
  stroke: #5c9f17;
  background: #8BE61B;
  border-color: #5c9f17;
}
.category-epoch-8{
  fill: #FFCA1C;
  stroke: #cb8f02;
  background: #FFCA1C;
  border-color: #cb8f02;
}
.category-0{
  fill: rgb(88, 165, 255);
  stroke: rgb(0, 69, 255);
  background: rgb(88, 165, 255);
  border-color: rgb(0, 69, 255);
}
.category-1{
  fill: #F88;
  stroke: #800;
  background: #F88;
  border-color: #800;
}
.category-artifact{
  fill: #FA0;
  stroke: #B60;
  background: #FA0;
  border-color: #B60;
}
.category-radiocarbon{
  fill: #FF3;
  stroke: #D80;
  background: #FF3;
  border-color: #D80;
}
.category-4{
  fill: #BFB;
  stroke: #070;
  background: #BFB;
  border-color: #070;
}
.category-5{
  fill: #9DF;
  stroke: #007;
  background: #9DF;
  border-color: #007;
}
.category-6{
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.category-site{
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.category-excavation{
  fill: #BFB;
  stroke: #070;
  background: #BFB;
  border-color: #070;
}
.category-research{
  fill: #F88;
  stroke: #800;
  background: #F88;
  border-color: #800;
}
.category-layer{
  fill: rgb(88, 165, 255);
  stroke: rgb(0, 69, 255);
  background: rgb(88, 165, 255);
  border-color: rgb(0, 69, 255);
}
.category-polygons{
  fill: #DEB887;
  stroke: #DEB887;
  background: #DEB887;
  border-color: #DEB887;
}
.category-culture{
  fill: #DB7093;
  stroke: #DB7093;
  background: #DB7093;
  border-color: #DB7093;
}
.category-assemblage{
  fill: #FFFAF0;
  stroke: #FFFAF0;
  background: #FFFAF0;
  border-color: #FFFAF0;
}
.category-lexeme{
  fill: #FF69B4;
  stroke: #FF69B4;
  background: #FF69B4;
  border-color: #FF69B4;
}
.category-term{
  fill: #BC8F8F;
  stroke: #BC8F8F;
  background: #BC8F8F;
  border-color: #BC8F8F;
}
.category-variant{
  fill: #FFFFE0;
  stroke: #FFFFE0;
  background: #FFFFE0;
  border-color: #FFFFE0;
}
.category-district{
  fill: #ADFF2F;
  stroke: #ADFF2F;
  background: #ADFF2F;
  border-color: #ADFF2F;
}
.category-locality{
  fill: #A0522D;
  stroke: #A0522D;
  background: #A0522D;
  border-color: #A0522D;
}
.category-person{
  fill: #2E8B57;
  stroke: #2E8B57;
  background: #2E8B57;
  border-color: #2E8B57;
}
.category-building{
  fill: #708090;
  stroke: #363f49;
  background: #708090;
  border-color: #363f49;
}
.category-encorganization{
  fill: #3CB371;
  stroke: #3CB371;
  background: #3CB371;
  border-color: #3CB371;
}

/*marker icons*/
/*.icon-1{*/
  /*!*background-image: url('running-16.png');*!*/
  /*!*background-repeat: no-repeat;*!*/
  /*!*background-position: 0px 1px;*!*/
/*}*/
/*.icon-2{*/
  /*background-image: url('bicycle-16.png');*/
  /*background-repeat: no-repeat;*/
  /*background-position: 1px 0px;*/
/*}*/
/*.icon-3{*/
  /*background-image: url('motorcycle-16.png');*/
  /*background-repeat: no-repeat;*/
  /*background-position: 1px 0px;*/
/*}*/
/*.icon-4{*/
  /*background-image: url('car-16.png');*/
  /*background-repeat: no-repeat;*/
  /*background-position: 1px 0px;*/
/*}*/

/*Popup*/
.map-popup span.heading {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}
.map-popup span.attribute {
  display: block;
}
.map-popup span.label {
  font-weight: bold;
}

.leaflet-container {
  overflow: hidden;
  background-color: #fff !important;
  height: 100%;
}

.leaflet-default-icon-path {
  background-image: url('/public/marker.svg');
}

.grey {
  filter: grayscale(100%);
}

.leaflet-marker-icon.leaflet-div-icon {
  border: none;
  background: transparent;
}

.leaflet-control-geocoder-alternatives {
  width: 450px;
  height: 200px;
  overflow: auto;
}

.map-download-link {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.leaflet-control-easyPrint-button-export {
  background-image: url(/static/media/download.64a0e753.svg);
  background-size: 16px 16px;
  cursor: pointer;
}

.leaflet-bar a, .leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.site-tooltip-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.site-tooltip {
  min-width: 250px;
  max-width: 500px;
  overflow: hidden;
}

.site-tooltip-item {
  white-space: normal;
}

.site-tooltip-item span{
  font-weight: bold;
  margin-right: 5px;
}

.map_legend {
  position: fixed;
  right: -5px;
  z-index: 1000;
  transform-origin: 100% 0;
  top: 100px; }
  .map_legend Button {
    background: #e7e7e7;
    padding-left: 3px;
    padding-right: 8px;
    margin-top: 20px; }

.map_legend_drawer .ant-drawer-body {
  padding: 5px; }

.map_legend_drawer .ant-collapse-borderless > .ant-collapse-item {
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 0;
  overflow: hidden; }

.map_legend_drawer .legend_item {
  display: flex;
  align-items: center;
  margin: 5px 0; }
  .map_legend_drawer .legend_item > img {
    margin-right: 10px; }
  .map_legend_drawer .legend_item_title {
    white-space: pre; }

.map-panel {
  width: 100% !important;
  height: 100% !important; }

.rel {
  position: fixed;
  left: calc(50% + 1em);
  top: 55px;
  width: calc(50% - 1.5em);
  height: calc(100vh - 110px);
  padding-right: 30px;
  z-index: 1; }

.gallery-cont {
  overflow-y: auto;
  position: fixed;
  left: calc(50% + 1em);
  top: 150px;
  width: calc(50% - 3rem);
  height: calc(80vh);
  padding: 25px 0 75px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  z-index: 1; }

.gallery-btn {
  top: 55vh;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #fafafa;
  border: 1px solid #e8e8e8; }

.btnRight {
  right: 2.7vw; }

.btnLeft {
  right: calc(47vw - 1.5rem); }

.gallery-img {
  width: 100%; }

.gallery-img-cont {
  width: 80%; }

.btnBlock {
  position: fixed;
  right: 0;
  top: 230px;
  z-index: 10000; }

.gallery {
  position: fixed;
  left: calc(50% + 1em);
  top: 65px;
  width: calc(50% - 3rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: black; }

.mapToggleButton {
  width: 37px;
  height: 37px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  border-radius: 5px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8; }

.map-tab-pane {
  height: 100%; }
  .map-tab-pane .ant-tabs-content {
    height: 85%; }

.map-tab {
  height: 100%;
  width: 100%;
  border: 1px solid; }

.sketchfab-embed-wrapper {
  width: 100%;
  height: 100%; }

.sketchfab-embed-wrapper iframe {
  width: 100%;
  height: 100%; }

.map-top-alert {
  width: 80%;
  margin-left: 10%;
  z-index: 1000;
  position: absolute;
  margin-top: 10px; }

.map-panel .ant-spin.ant-spin-spinning {
  position: absolute;
  top: 50px;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.39);
  padding: 100px;
  width: 100%;
  height: 100%; }

.map-is-snapshot {
  /* position: relative; */ }

.icon-container {
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: -30px;
  justify-content: end;
  position: absolute;
  padding-right: 25px; }

.icon {
  box-sizing: content-box; }

.icon-b {
  padding: 5px;
  background-color: #1F1F1F; }

.simple-multiple-input {
  line-height: 25px; }
  .simple-multiple-input .ant-tag-checkable {
    margin: 0px 1px;
    border: #cccccc solid 1px; }
    .simple-multiple-input .ant-tag-checkable.artifact-material {
      border-color: #cccccc;
      background: rgba(233, 233, 233, 0.3); }
    .simple-multiple-input .ant-tag-checkable.artifact-material-1 {
      border-color: #cccccc;
      background: rgba(208, 208, 208, 0.1); }
    .simple-multiple-input .ant-tag-checkable.artifact-material-2 {
      border-color: #669933;
      background: rgba(102, 153, 51, 0.2); }
    .simple-multiple-input .ant-tag-checkable.artifact-material-3 {
      border-color: #faebd7;
      background: rgba(250, 235, 215, 0.5); }
    .simple-multiple-input .ant-tag-checkable.artifact-material-4 {
      border-color: #6699CC;
      background: rgba(102, 153, 204, 0.1); }
    .simple-multiple-input .ant-tag-checkable.artifact-material-5 {
      border-color: #cccc99;
      background: rgba(204, 204, 153, 0.1); }
    .simple-multiple-input .ant-tag-checkable.artifact-material-6 {
      border-color: #cc6666;
      background: rgba(204, 102, 102, 0.1); }
  .simple-multiple-input .ant-tag-checkable-checked {
    border: #ffffff solid 1px; }
    .simple-multiple-input .ant-tag-checkable-checked.artifact-material {
      border-color: Black;
      color: black;
      background: #e9e9e9; }
    .simple-multiple-input .ant-tag-checkable-checked.artifact-material-1 {
      border-color: #cccccc;
      color: black;
      background: #d0d0d0; }
    .simple-multiple-input .ant-tag-checkable-checked.artifact-material-2 {
      border-color: #669933;
      background: #669933; }
    .simple-multiple-input .ant-tag-checkable-checked.artifact-material-3 {
      border-color: #faebd7;
      color: black;
      background: antiquewhite; }
    .simple-multiple-input .ant-tag-checkable-checked.artifact-material-4 {
      border-color: #6699CC;
      background: #6699cc; }
    .simple-multiple-input .ant-tag-checkable-checked.artifact-material-5 {
      border-color: #cccc99;
      color: black;
      background: rgba(204, 204, 153, 0.7); }
    .simple-multiple-input .ant-tag-checkable-checked.artifact-material-6 {
      border-color: #cc6666;
      background: #cc6666; }

.file-upload-fields {
  border: #aaaaaa dashed 1px;
  border-radius: 5px;
  margin-bottom: 5px; }

.drag_drop_warning {
  font-size: 13px;
  font-style: italic; }

.ant-form-item-children .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
}

.ant-form-item-children .react-tel-input .flag-dropdown:hover {
    background-color: transparent;
    border: none;
}

.ant-form-item-children .react-tel-input .flag-dropdown:hover .selected-flag {
    background-color: transparent;
}

.ant-form-item-children .react-tel-input input[type=tel] {
    width: 268px;
}

.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content {
    width: 100%;
    box-sizing: content-box;
    padding: 0;
}

.account_confirm .ant-alert {
  margin-bottom: 10px;
}

.account_confirm {
  text-align: left;
}

.account_confirm .user-must-confirm {
  font-weight: bold;
}

.user-rol {
  text-align: right;
  padding: 0 20px;
  color: grey;
}

.user-rol .role {
  font-weight: bold;
  margin-left: 5px;
}

.ant-form-item-children {
  text-align: left;
}
.change-password {
    text-align: right;
    margin: 15px 0;
}
.organization-list-item {
  border-top: 1px solid #e8e8e8;
  padding-top: 25px;
  border-bottom: none;
  position: relative;
}

.organization-list-item i {
  position: absolute;
  top: 37px;
  right: -18px;
  cursor: pointer;
}

.organization-add-button {
  width: 100%;
}
.user_info {
  text-align: left;
  min-width: 300px;
  height: 100%;
}

.user_info .ant-card-meta-title {
  white-space: inherit;
}

.user_info .ant-card-meta {
  padding-bottom: 10px;
}

.user_info .ant-card-body {
  height: calc(100% - 47px);
}

.researcher_item {

}

.researcher_list {
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.researcher_container .form_container{
  max-width: 500px;
  padding: 5px 20px;
  margin: 0 auto;
}

.form_container .ant-form-item {
  margin-bottom: 0px;
}

.form_container .ant-btn {
  margin: 10px 0;
}

.user-confirm-dismiss {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
}

.user-confirm-dismiss:hover {
  opacity: 0.8;
}

.user-confirm-dismiss:focus,
.user-confirm-dismiss:active{
  outline: none;
}

.user-confirm-confirm {
  background: transparent;
  border: none;
  color: blue;
  cursor: pointer;
}

.user-confirm-confirm:hover {
  opacity: 0.8;
}

.user-confirm-confirm:focus,
.user-confirm-confirm:active{
  outline: none;
}

.existing-user-card {
  text-align: left;
  padding: 0 15px;
}

.existing-user-card-list .ant-spin-nested-loading {
  border: 1px solid grey;
  border-radius: 5px;
}

.user-confirmation-block .ant-list-item-meta-description span{
  color: grey;
  font-size: 14px;
  margin-right: 5px;
}


.ant-notification.ant-notification-topRight {
  width: 500px;
}

.existing-user-modal .ant-card-head-wrapper{
  display: block;
}

.existing-user-modal .ant-card-head-wrapper .ant-card-head-title {
  padding-bottom: 0;
}

.ant-notification.ant-notification-topRight .ant-card-body{
  display: none;
}

span.existing-status {
  color: #ff0000c4;
  margin: 0 5px;
  font-size: 12px;
}

.existing-user-card .ant-list-item-meta-description a {
  display: inline-block;
}

.avatar-uploader > .ant-upload img {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.avatar-upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-upload i.anticon.anticon-close {
  font-size: 20px;
  cursor: pointer;
}

.ant-upload-picture-card-wrapper {
  flex: 0 1;
}

.type_title .ant-badge {
  margin-left: 5px; }

.request_access {
  font-size: 14px;
  padding-bottom: 10px; }
  .request_access_text {
    flex: 1 0;
    padding: 5px; }
  .request_access .ant-tag-orange {
    font-size: 14px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: inherit; }
    .request_access .ant-tag-orange i {
      margin-right: 5px; }
  .request_access_is_send i {
    margin-right: 5px; }

.adv-search-field .adv-search-group {
  display: block; }

.adv-search-field-input.input, .adv-search-field-input.select {
  flex: 1 0;
  margin: 10px; }

.adv-search-field.input {
  display: flex;
  align-items: center; }
  .adv-search-field.input .field-remove-button {
    position: relative;
    top: 0;
    right: 0; }

.adv-search-field.select {
  display: flex;
  align-items: center; }
  .adv-search-field.select .field-remove-button {
    position: relative;
    top: 0;
    right: 0; }


.adv-search-field {
  position: relative;
  text-align: left;
  vertical-align: top;
  margin: 10px 0;
  background-color: rgba(179, 177, 177, 0.1);
  padding: 10px; }
  .adv-search-field .ant-checkbox-wrapper {
    vertical-align: -webkit-baseline-middle; }
  .adv-search-field .field-remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
    filter: grayscale(100);
    opacity: 0.2;
    transition: all 0.3s; }
  .adv-search-field:hover > .field-remove-button {
    filter: grayscale(0);
    opacity: 1; }
  .adv-search-field.not_choosen {
    display: flex;
    align-items: center; }

.adv-search-field > * {
  display: inline-block;
  vertical-align: text-top; }

.adv-search-block {
  margin-top: 10px;
  position: relative; }
  .adv-search-block-body {
    border-left: 2px solid #3690ff;
    position: relative;
    padding: 0 25px;
    margin-top: 10px; }
  .adv-search-block .field-add-button {
    margin-right: 10px; }

.ant-radio-group {
  display: block; }

.result_table {
  position: relative;
  left: -15px; }
  .result_table_sorter {
    padding: 10px; }
    .result_table_sorter_tag {
      padding: 5px 10px;
      cursor: pointer; }
      .result_table_sorter_tag i {
        font-size: 16px;
        vertical-align: text-bottom; }

.show-selection {
  padding: 10px;
  overflow: hidden; }
  .show-selection h1 {
    display: inline-block; }
  .show-selection-edit {
    margin: 0 10px;
    color: #b6b2b0; }
  .show-selection-content {
    display: grid;
    grid-template-columns: 6fr 4fr; }
    .show-selection-content .map-panel {
      width: calc(40% - 1.5em);
      left: calc(60% + 1em);
      height: calc(100% - 100px); }
  .show-selection-button-group > * {
    margin: 0 10px 10px 10px; }
  .show-selection-user-select {
    margin: 20px 0 0 0; }
    .show-selection-user-select .ant-select {
      max-width: 330px;
      margin: 10px 0; }
  .show-selection-user-list {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    max-width: 330px;
    margin-top: 20px; }
    .show-selection-user-list .ant-list-header {
      padding: 10px; }
    .show-selection-user-list .ant-list-item {
      padding-left: 10px; }

.selection-access {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap; }
  .selection-access-control {
    margin-right: 10px; }
  .selection-access > * {
    flex: 1 0 330px; }

.shadow-table .ant-table-content {
  box-shadow: inset -6px 0px 10px 1px #dcdcdc; }

.shadow-table .ant-table-fixed {
  box-shadow: inset -6px 0px 10px 13px white; }

.adv-search-download > * {
  margin: 0 10px; }

.research-list .ant-spin-container {
  text-align: left;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  max-height: 300px;
  margin-bottom: 20px; }

.research-list .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45); }

.research-list-access-edit {
  margin: 10px;
  float: right; }

.source-list {
  text-align: left;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  margin-bottom: 20px; }

.source-list .ant-list-item-meta-description span {
  display: block; }

.ant-table-tbody a {
  display: block; }

.modal-container {
  position: relative;
}

.selection-modal {
  position: absolute;
  background-color: #fff;
  top: 115px;
  right: 500px;
  z-index: 5;
  min-width: 700px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 4px 10px rgba(105, 105, 105, 0.15), 0 2px 4px rgba(105, 105, 105, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
}

.selection-item {
  display: flex;
  min-height: 35px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 700px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
}

.close-btn {
  left: 97%;
  position: absolute;
  cursor: pointer;
}

.lds-ring {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 50px;
  left: 50%;
  z-index: 100;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 4px solid lightgrey;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: lightgrey transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.isOkModal {
  position: fixed;
  top: 50px;
  left: 50%;
  width: 250px;
  height: 100px;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(105, 105, 105, 0.15), 0 2px 4px rgba(105, 105, 105, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.confirm-btn {
  display: flex;
  align-items: center;
  padding: 5px;
  color: #fff;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  background-color: #1890ff;
  cursor: pointer;
  outline: none;
}

.main {
  width: 50%;
  margin: auto;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 125px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.year {
  padding: 5px;
  outline: none;
  border: 1px solid silver;
  border-radius: 5px;
}

.getBtn {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
}

.stat_container {
  font-family: "Roboto", sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.title_stat {
  font-size: 32px;
  color: black;
  margin-bottom: 20px;
}

.input_block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
}
.input {
}
.model {
  display: flex;
  justify-content: center;
}

.input_title {
  font-size: 14px;

}

.date_input {
  width: 300px;
  background-color: #ffffff;
  padding: 10px 15px;
  color: #000000;
  border-radius: 10px;
  border: 1px solid #E3E9ED;
  margin-top: 12px;
  font-size: 14px;
}
::-webkit-calendar-picker-indicator{
  background-color: #ffffff;
  padding-left: 150px;
  cursor: pointer;
  border-radius: 3px;
}

.model_input {
  background-color: #ffffff;
  width: 300px;
  padding: 10px 15px;
  border: 1px solid #E3E9ED;
  margin-top: 12px;
  outline: none;
  font-size: 14px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.models_list {
  top: 42px;
  background-color: #FFFFFF;
  left: 0;
  border: 1px solid #E3E9ED;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  display: grid;
  overflow-y: auto;
  position: absolute;
}
.display_none {
  display: none;
}

.models_list_item {
  color: black;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
}

.models_list_item:hover {
  background: rgba(59,59,59, 0.2);
  border-radius: 2px;
}

.button_container {
  margin-top: 20px;;
}

.button_stat {
  margin: 12px auto 0 auto;
  width: 300px;
  background-color: #0080ff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 10px;
  border: none;
  padding: 10px 15px;
}
.button_stat:disabled,
.button_stat[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.result {
  margin-top: 20px;
  border: 1px solid #E3E9ED;
  padding: 20px;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 14px;
  border-radius: 10px;
}


.container.ling-about {
  padding: 10px;
}

.ling-about img {
  width: 100%;
  margin: 20px 0;
}

.ling-about p {
  text-indent: 1.5em;
  font-size: 16px;
}

.container.recovery {
    text-align: center;
    margin-top: 20px;
  }
  
.ling-index-bg {
  top: 0;
  position: absolute;
  z-index: -1;
  width: 100vw;
  overflow: hidden;
  height: 456px;
  perspective: 4000px;
}

.ling-index-bg .cd-floating-background {
  transform-style: preserve-3d;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.2s;
}

.ling-index-bg .people {
  position: absolute;
  /*transform: translateZ(50px);*/
  backface-visibility: hidden;
  transition: all 0.5s ease-out;
}

.ling-index-bg .ling-index-bg-img {
  opacity: .9;
  width: 110%;
  height: 110%;
  object-fit: cover;
  left: -5%;
  position: relative;
  top: -5%;
}

.ling-index-research-link {
  color: #e0c592;
  text-decoration: underline;
}

.ling-index-research-link:hover {
  color: #e0c592;
  text-decoration: none;
  opacity: 0.8;
}

.ling-index main{
  color: #fff;
  width: 1200px;
  margin: 33px auto;
  padding: 0 15px;
}

.ling-index main header{
  text-align: center;
}

.ling-index h1{
  color: #fff;
  font-family: 'Roboto Slab', serif;
  font-size: 48px;
  text-transform: uppercase;
}

.ling-index .tile{
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  box-shadow: 0px 5px 20px rgba(91, 81, 76, 0.3);
  transition: all 0.3s;
}

.ling-index .tile:hover {
  box-shadow: 0px 5px 20px 4px rgba(91, 81, 76, 0.3);
}

.ling-index .tile img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #fff;
}

.ling-index .short-column {
  margin-top: 48px;
  width: 100%;
  display: inline-block;
}

.ling-index .short-column .ling-tile {
  height: 245px;
}

.ling-index .short-column .ling-tile img{
  top: inherit;
  bottom: 0;
}

.ling-index .ling-what {
  cursor: default;
  background-color: #fff;
}

.ling-index h3{
  font-family: Roboto Slab,serif;
  text-transform: uppercase;
  float: left;
  font-size: 18px;
  color: #fff;
  background-color: #e0c592;
  display: inline-block;
  padding: 10px 30px;
}

.ling-index .search-block {
  margin: 46px 0;
}

.ling-index .search-block .ant-select-selection--single{
  height: 46px;
  background-color: transparent;
  color: #fff;
}

.ling-index .search-block .ant-select-open .ant-select-selection {
  box-shadow: none;
  border-color: #fff;
}

.ling-index .search-block .ant-select-focused .ant-select-selection,
.ling-index .search-block .ant-select-selection:focus, .ant-select-selection:active {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  border-color: #fff;
}

.ling-index .search-block .ant-select-arrow {
  color: #fff;
  opacity: 0.6;
}

.ling-index .search-block .ant-select-selection__rendered {
  line-height: 44px;
  margin-left: 30px;
}

.ling-index .search-block .ant-input-search input{
  color: #fff;
  height: 46px;
  background-color: transparent;
}

.ling-index .search-block .ant-input-search-enter-button .ant-btn-primary{
  background-color: #e0c592;
  border-color: #e0c592;
  width: 48px;
  height: 46px;
  border-radius: 0;
  transition: opacity 0.3s;
}

.ling-index .search-block .ant-input-search-enter-button .ant-btn-primary:hover,
.ling-index .search-block .ant-input-search-enter-button .ant-btn-primary:focus {
  opacity: 0.8;
}

.ling-index .search-block .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection {
  border-radius: 0;
  border-right-width: 0 !important;
}

.ling-index .search-block .ant-select-selection:hover,
.ling-index .search-block .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #fff;
  border-right-width: 0 !important;
}

.ling-index .search-block .ant-input:focus {
  border-color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

.search-panel {
  text-align: left;
  width: 50%;
  height: 100%;
  padding: 10px 0 80px; }

.search-panel-header {
  text-align: center;
  width: 80%;
  padding-left: 15px;
  margin: 0 auto;
  margin-bottom: 30px; }

.search-form.ant-form {
  max-width: 700px;
  padding-right: 10px;
  margin: 30px;
  margin-left: 10%; }

form.search-form div.ant-row.ant-form-item div.ant-form-item-label {
  display: inline-table; }

.search-form-button {
  width: 100%; }

.search-page-pagination {
  margin-top: 20px; }

.leaflet-control-geocoder-alternatives li {
  white-space: normal; }

.leaflet-control-geocoder-alternatives li a {
  width: 100% !important;
  line-height: 14px !important; }

.Pagination_pagination__5FDIp {
  background: #F9F9F9;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px 5px; }
  .Pagination_pagination__5FDIp .Pagination_search_page_pagination__2AvFX {
    margin-top: 0;
    margin-left: auto;
    margin-right: 10px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif; }
    .Pagination_pagination__5FDIp .Pagination_search_page_pagination__2AvFX .ant-pagination-item-active {
      background: #1890ff; }
      .Pagination_pagination__5FDIp .Pagination_search_page_pagination__2AvFX .ant-pagination-item-active a {
        color: white; }

.Pagination_show_num__2YY4t {
  display: flex;
  align-items: center;
  margin-left: 10px; }
  .Pagination_show_num__2YY4t span {
    color: #8A8A8A; }
  .Pagination_show_num_list__1GsDy {
    padding: 0;
    margin: 0; }
    .Pagination_show_num_list__1GsDy li {
      display: inline-block;
      margin: 0 6px;
      text-decoration: underline;
      color: #545454;
      cursor: pointer;
      height: 24px;
      line-height: 22px;
      padding: 0 4px; }
      .Pagination_show_num_list__1GsDy li.Pagination_active__1m244 {
        border: 1px solid #1890ff;
        border-radius: 4px;
        font-weight: 500;
        background: #1890ff;
        color: #fff;
        text-decoration: none;
        padding: 0 4px; }
      .Pagination_show_num_list__1GsDy li:hover, .Pagination_show_num_list__1GsDy li:focus {
        text-decoration: none;
        opacity: 0.8; }

.Pagination_num__2fk6F {
  color: #8A8A8A;
  margin-bottom: 20px;
  padding-left: 15px; }
  .Pagination_num__2fk6F span {
    color: #545454; }


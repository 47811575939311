.add-spatials-modal-window {
  display: flex;
}

.add-spatials-modal-left-panel {
  width: 100%;
}

.add-spatials-modal-map-panel {
  width: 100%;
  height: 700px;
}

.ant-select {
  width: 100%;
}

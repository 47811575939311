.stat_container {
  font-family: "Roboto", sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.title_stat {
  font-size: 32px;
  color: black;
  margin-bottom: 20px;
}

.input_block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
}
.input {
}
.model {
  display: flex;
  justify-content: center;
}

.input_title {
  font-size: 14px;

}

.date_input {
  width: 300px;
  background-color: #ffffff;
  padding: 10px 15px;
  color: #000000;
  border-radius: 10px;
  border: 1px solid #E3E9ED;
  margin-top: 12px;
  font-size: 14px;
}
::-webkit-calendar-picker-indicator{
  background-color: #ffffff;
  padding-left: 150px;
  cursor: pointer;
  border-radius: 3px;
}

.model_input {
  background-color: #ffffff;
  width: 300px;
  padding: 10px 15px;
  border: 1px solid #E3E9ED;
  margin-top: 12px;
  outline: none;
  font-size: 14px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.models_list {
  top: 42px;
  background-color: #FFFFFF;
  left: 0;
  border: 1px solid #E3E9ED;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  display: grid;
  overflow-y: auto;
  position: absolute;
}
.display_none {
  display: none;
}

.models_list_item {
  color: black;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
}

.models_list_item:hover {
  background: rgba(59,59,59, 0.2);
  border-radius: 2px;
}

.button_container {
  margin-top: 20px;;
}

.button_stat {
  margin: 12px auto 0 auto;
  width: 300px;
  background-color: #0080ff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 10px;
  border: none;
  padding: 10px 15px;
}
.button_stat:disabled,
.button_stat[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.result {
  margin-top: 20px;
  border: 1px solid #E3E9ED;
  padding: 20px;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 14px;
  border-radius: 10px;
}

.map-panel {
  width: 100% !important;
  height: 100% !important;
}

.rel {
  position: fixed;
  left: calc(50% + 1em);
  top: 55px;
  width: calc(50% - 1.5em);
  height: calc(100vh - 110px);
  padding-right: 30px;
  z-index: 1;
}

.gallery-cont {
  overflow-y: auto;
  position: fixed;
  left: calc(50% + 1em);
  top: 150px;
  width: calc(50% - 3rem);
  height: calc(80vh);
  padding: 25px 0 75px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  z-index: 1;
}

.gallery-btn{
  top: 55vh;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
}

.btnRight{
  right: 2.7vw;
}

.btnLeft{
  right: calc(47vw - 1.5rem);
}

.gallery-img{
  width: 100%;
}

.gallery-img-cont{
  width: 80%;
}

.btnBlock {
  position: fixed;
  right: 0;
  top: 230px;
  z-index: 10000;
}

.gallery{
  position: fixed;
  left: calc(50% + 1em);
  top: 65px;
  width: calc(50% - 3rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: black;
}

.mapToggleButton {
  width: 37px;
  height: 37px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  border-radius: 5px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;

}

.map-tab-pane {
  height: 100%;

  .ant-tabs-content {
    height: 85%;
  }
}

.map-tab {
  height: 100%;
  width: 100%;
  border: 1px solid;
}

.sketchfab-embed-wrapper {
  width: 100%;
  height: 100%;
}

.sketchfab-embed-wrapper iframe {
  width: 100%;
  height: 100%;
}

.map-top-alert {
  width: 80%;
  margin-left: 10%;
  z-index: 1000;
  position: absolute;
  margin-top: 10px;
}

.map-panel .ant-spin.ant-spin-spinning {
  position: absolute;
  top: 50px;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.39);
  padding: 100px;
  width: 100%;
  height: 100%;
}

.map-is-snapshot {
  /* position: relative; */
}

.icon-container {
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: -30px;
  justify-content: end;
  position: absolute;
  padding-right: 25px;
}

.icon {
  box-sizing: content-box;
}

.icon-b {
  padding: 5px;
  background-color: #1F1F1F;
}

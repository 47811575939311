.footer {
  background: #5b514c;
  height: 60px;
  /*border-top: 1px solid #d9d9d9;*/
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  a {
    margin: 0 10px;
    color: #fff;
    opacity: 0.6;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }
  img {
    height: 36px;
    margin: 12px 22px;
    float: right;
    border-radius: 5px;
    opacity: 0.7;
  }
  .copyright {
    padding: 20px;
    display: inline-block;
  }
  .logos {
    display: inline-block;
    float: right;
  }
  .logos a {
    padding: 20px;
    display: inline-block;
  }

  .cert-link__wrapper {
    display: inline-block;
    float: right;

    .cert-link {
      margin: 0;
      padding: 0;
      display: inline-block;
      opacity: 1;
    }
  }


}



@media (max-width: 820px) {

  .footer {
    text-align: center;
    position: relative;
    height: inherit;
    z-index: inherit;

    .copyright {
      display: block;
      padding: 10px;
      margin-top: 10px;
    }
    .logos {
      float: none;
      margin-bottom: 10px;
    }
    .logos a {
      display: block;
      padding: 5px;
    }
    img {
      float: none;
    }
  }
}

.font-family-mixin {
  font-family: 'Roboto', sans-serif;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}
button.link {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.background {
  display: block;
  width: 100vw;
  min-height: calc(135vh);
  z-index: -1;
  position: absolute;
  top: -35px;
  left: 0;
  background-size: cover;
  height: calc(135%);
}
.background + .search-panel {
  margin: auto;
}
.container {
  margin-bottom: 60px;
}

form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group, form:not(.ant-form-vertical) .ant-input-group-wrapper{
  top: 0 !important;
}

.gallery-slide {
  min-height: 450px !important;
  height: content-box !important;
}

.ant-table-wrapper {
  min-height: 700px;
}

.ant-table-placeholder {
  z-index: 0;
}

@font-face {
  font-family: 'Izhitsa Regular';
  src: url("/Fonts/Izhitsa-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BukyVede';
  src: url("/Fonts/BukyVede-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fedorovsk Unicode';
  src: url("/Fonts/FedorovskUnicode.ttf");
  font-weight: normal;
  font-style: normal;
}
